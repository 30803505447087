import rec2d from "./graphCompute/rec2d";
import arc2d from "./graphCompute/arc2d";
import rec3d from "./graphCompute/rec3d";
import cyl3d from "./graphCompute/cyl3d";
import circ3d from "./graphCompute/circ3d";

const graphTypes = [
  "rec2d",
  "arc2d",
  "rec3d",
  "cyl3d",
  "circ3d",
]

const typeOrders = {
  "rec2d": ["x", "y"],
  "arc2d": ["r", "\\theta"],
  "rec3d": ["x", "y", "z"],
  "cyl3d": ["z", "r", "\\theta"],
  "circ3d": ["\\theta", "\\phi", "\\rho"],
}

export class genericGraph {
  constructor(root) {
    this.root = root;
    this.id = `GG${genericGraph.MathBoxObjectsCreated}`;
    genericGraph.MathBoxObjectsCreated++;

    this.graphType = null;

    this.surfaceData = [];
    this.edgeData = [];
    this.chunksInfo = [];
    this.swizzle = "yzx";

    this.surfaceSettings = {
      color: 0xFFFFFF,
      visible: true,
    }
    this.edgeSettings = {
      color: 0x000000,
      visible: true,
      zBias: 1,
    }
  }
  static MathBoxObjectsCreated = 0;

  computeData(order, bounds, maxDels) {
    // determine what kind of graph it is
    // console.log(order);
    for (const graphType of graphTypes) {
      const tempComp = [...typeOrders[graphType]];
      const tempOrd = [...order];
      if (tempComp.sort().join(",") === tempOrd.sort().join(",")) {
        this.graphType = graphType;
      }  
    }
    if (this.graphType === null) throw new Error("Graph order not defined.");

    // reset previous data
    this.surfaceData = [];
    this.edgeData = [];
    this.chunksInfo = [];

    switch (this.graphType) {
      case "rec2d": {
        const data = rec2d.rec2dGetData(bounds.a, bounds.b, bounds.c, bounds.d, 0, maxDels[order[1]], maxDels[order[0]], order);
        this.surfaceData = data.surfaceData;
        this.edgeData = data.edgeData;
        this.chunksInfo = data.chunksInfo;
        break;
      }
      case "arc2d": {
        const data = arc2d.arc2dGetData(bounds.a, bounds.b, bounds.c, bounds.d, 0, maxDels[[order[1]]], maxDels[order[0]], order);
        this.surfaceData = data.surfaceData;
        this.edgeData = data.edgeData;
        this.chunksInfo = data.chunksInfo;
        break;
      }
      case "rec3d": {
        const data = rec3d.rec3dGetData(bounds.a, bounds.b, bounds.c, bounds.d, bounds.e, bounds.f, maxDels[order[2]], maxDels[order[1]], maxDels[order[0]], order);
        this.surfaceData = data.surfaceData;
        this.edgeData = data.edgeData;
        this.chunksInfo = data.chunksInfo;
        break;
      }
      case "cyl3d": {
        const data = cyl3d.cyl3dGetData(bounds.a, bounds.b, bounds.c, bounds.d, bounds.e, bounds.f, maxDels[order[2]], maxDels[order[1]], maxDels[order[0]], order);
        this.surfaceData = data.surfaceData;
        this.edgeData = data.edgeData;
        this.chunksInfo = data.chunksInfo;
        break;
      }
      case "circ3d": {
        const data = circ3d.circ3dGetData(bounds.a, bounds.b, bounds.c, bounds.d, bounds.e, bounds.f, maxDels[order[2]], maxDels[order[1]], maxDels[order[0]], order);
        this.surfaceData = data.surfaceData;
        this.edgeData = data.edgeData;
        this.chunksInfo = data.chunksInfo;
        break;
      }
    }
  }

  render() {
    switch(this.graphType) {
      case "rec2d": {
        rec2d.rec2dRender(this.surfaceData, this.edgeData, this.chunksInfo, this.id, this.root, this.swizzle, this.color);
        break;
      }
      case "arc2d": {
        arc2d.arc2dRender(this.surfaceData, this.edgeData, this.chunksInfo, this.id, this.root, this.swizzle, this.color);
        break;
      }
      case "rec3d": {
        rec3d.rec3dRender(this.surfaceData, this.edgeData, this.chunksInfo, this.id, this.root, this.swizzle, this.color);
        break;
      }
      case "cyl3d": {
        cyl3d.cyl3dRender(this.surfaceData, this.edgeData, this.chunksInfo, this.id, this.root, this.swizzle, this.color);
        break;
      }
      case "circ3d": {
        circ3d.circ3dRender(this.surfaceData, this.edgeData, this.chunksInfo, this.id, this.root, this.swizzle, this.color);
        break;
      }
    }

    this.applySettings(this.surfaceSettings, this.edgeSettings);
  }
  applySettings(surfaceSettings, edgeSettings) {
    Object.keys(surfaceSettings).forEach(key => {
      window.mathbox.select(`#${this.id} > .surface`).set(key, surfaceSettings[key]);
      window.mathbox.select(`#${this.id} > .side`).set(key, surfaceSettings[key]);
      if (key === "color") {
        window.mathbox.select(`#${this.id} > .side`).set(key, surfaceSettings[key] - 0x101010);
      }
    })
    Object.keys(edgeSettings).forEach(key => {
      window.mathbox.select(`#${this.id} > .edge`).set(key, edgeSettings[key]);
    })
  }

  getSettings(key, which) {
    if (which === "surface") {
      return this.surfaceSettings[key];
    }
    if (which === "edge") {
      return this.edgeSettings[key];
    }
  }

  setSettings(key, value, which) {
    if (which === "surface") {
      if (this.surfaceSettings[key] !== value) {
        this.surfaceSettings[key] = value;
        window.mathbox.select(`#${this.id} > .surface`).set(key, value);
        window.mathbox.select(`#${this.id} > .side`).set(key, value);
        if (key === "color") {
          this.surfaceSettings[key] = parseInt(value.replace(/^#/, ''), 16);
          window.mathbox.select(`#${this.id} > .side`).set(key, this.surfaceSettings[key]-0x101010);
        }
      }
    }
    if (which === "edge") {
      if (this.edgeSettings[key] !== value) {
        this.edgeSettings[key] = value;
        window.mathbox.select(`#${this.id} > .edge`).set(key, value);
      }
    }
  }


  remove() {
    window.mathbox.select(`#${this.id}`).remove();
  }
}