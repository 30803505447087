import React from 'react';
import { AxisContainer } from './AxisGraphContainer/AxisContainer';
import { GraphContainer } from './AxisGraphContainer/GraphContainer';

export const AxisGraphContainer = () => {
  return (
    <div id="AxisGraphContainer">
      <AxisContainer />
      <GraphContainer />  
    </div>
  );
};