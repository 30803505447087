import React from "react";
import "./ExampleClickable.css";
export const ExampleClickable = (props) => {
  return (
    <div 
      className="ExampleClickable" 
      onClick={props.onClick} 
      style={{ 
        backgroundSize: "cover", 
        backgroundImage: `url(${require(`../../../assets/exampleImgs/${props.img}`).default})`
      }}
    >
      <h1 className="ECTitle">{props.label}</h1>
    </div>
  );
}