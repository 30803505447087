import React from 'react';
import { MathQuillInputMemo } from "../../inputareas/MathQuillInput";
import { MathQuillStatic } from "../../inputareas/MathQuillStatic";

import "./SettingsControls.css";

const Colors = (props) => {
  const colors = ["#FFFFFF", "#FF9AA2", "#FFB7B2", "#FFDAC1", "#E2F0CB", "#B5EAD7", "#ADD8E6", "#C7CEEA"];

  const onClick = (col) => () => {
    // console.log(col);
    window.mathboxGraphs[props.graphId].setSettings("color", col, "surface");
    props.changeColor(col);
  }

  React.useEffect(() => {
    if (props.initSettings?.color) {
      onClick(props.initSettings?.color)();
    }
  }, []);

  return (
    colors.map(color => (
      <div key={color} className="singleColor" style={{backgroundColor: color}} onClick={onClick(color)}></div>
    ))
  )
}

export const SettingsControls = (props) => {
  const updateOp = (val) => {
    val = parseFloat(val, 10);
    if (val !== "" && !isNaN(val) && val >= 0 && val <= 1) {
      window.mathboxGraphs[props.graphId].setSettings("opacity", val, "surface");
    }    
  }

  const toggleSurface = () => {
    const curr = window.mathboxGraphs[props.graphId].getSettings("visible", "surface");
    window.mathboxGraphs[props.graphId].setSettings("visible", !curr, "surface");
  }

  const toggleEdge = () => {
    const curr = window.mathboxGraphs[props.graphId].getSettings("visible", "edge");
    window.mathboxGraphs[props.graphId].setSettings("visible", !curr, "edge");
  }

  return (
    <>
    <div className="SettingsControls">
      <div className="ChooseColors">
        <Colors graphId={props.graphId} initSettings={props.initSettings} changeColor={props.changeColor} />
      </div>
      <div className="OtherSettings">
        <div className="OtherSettingsSett">
          <MathQuillStatic latex={"Opacity: "} />
          <MathQuillInputMemo 
            latex={"1"} 
            onEdit={updateOp}
            initFocus={false}
          />
        </div>
        <div className="OtherSettingsSett">
          <button onClick={toggleSurface}>Toggle Surface</button>
        </div>
        <div className="OtherSettingsSett">
          <button onClick={toggleEdge}>Toggle Edge</button>
        </div>  
      </div>
    </div>
    </>
  )
}