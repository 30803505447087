import React from "react";
import "./containers.css"
import { TitleContainer } from "./LeftColumn/TitleContainer";
import { AxisGraphContainer } from "./LeftColumn/AxisGraphContainer";

export const LeftColumnContainer = () => {
  return (
    <div id="LeftColumnContainer">
      <TitleContainer />
      <AxisGraphContainer />
    </div>
  )
};