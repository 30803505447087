import { arccData } from "../GraphObjects/objectData";

const arc2dGetData = (a, b, c, d, otherVar, maxDelOut, maxDelIn, order) => {
  let surfaceData = [];
  let edgeData = [];
  let chunksInfo = [];
  const maxChunk = Math.pow(2, 14);

  let chunks = {
    vertexesPerUnit: 0,
    vertexesSoFar: 0,
    curr: [],
  }

  const segsOut = Math.ceil(Math.abs(b - a) / maxDelOut);
  const delOut = (b - a) / segsOut;
  for (let i=0; i<segsOut; i++) {
    const iterOut = i * delOut + a;

    const segsIn = Math.ceil(Math.abs(d(iterOut) - c(iterOut)) / maxDelIn);
    const delIn = (d(iterOut) - c(iterOut)) / segsIn;

    for (let j=0; j<segsIn; j++) {
      const iterIn = j * delIn + c(iterOut);
      
      if (chunks.vertexesSoFar + chunks.vertexesPerUnit >= maxChunk) {
        chunksInfo.push({
          units: chunks.vertexesSoFar / chunks.vertexesPerUnit,
          vertexesPerUnit: chunks.vertexesPerUnit,
        });
        surfaceData.push(chunks.curr);
        chunks.curr = [];
        chunks.vertexesSoFar = 0;
      }
      let arcData = null;
      if (order.join(",") === "r,\\theta")
        arcData = arccData(iterIn, iterIn + delIn, iterOut, iterOut + delOut, otherVar, 1).arcData;
      else if (order.join(",") === "\\theta,r")
        arcData = arccData(iterOut, iterOut + delOut, iterIn, iterIn + delIn, otherVar, 1).arcData;
        
      chunks.vertexesPerUnit = arcData.length;
      chunks.curr.push(...arcData);
      chunks.vertexesSoFar += chunks.vertexesPerUnit;
    }
  }
  surfaceData.push(chunks.curr);
  chunksInfo.push({
    units: chunks.vertexesSoFar / chunks.vertexesPerUnit,
    vertexesPerUnit: chunks.vertexesPerUnit,
  });

  for (const [i, chunk] of surfaceData.entries()) {
    let edgeChunk = [];
    for (let vert=-1; vert<chunksInfo[i].vertexesPerUnit/2 + 1; vert++) {
      for (let unit=0; unit<chunksInfo[i].units; unit++) {
        if (vert === -1) {
          edgeChunk.push(chunk[unit * chunksInfo[i].vertexesPerUnit + 1]);
          edgeChunk.push(chunk[unit * chunksInfo[i].vertexesPerUnit + 0]);
        }
        else if (vert === chunks.vertexesPerUnit/2) {
          edgeChunk.push(chunk[unit * chunksInfo[i].vertexesPerUnit + (chunksInfo[i].vertexesPerUnit-1)]);
          edgeChunk.push(chunk[unit * chunksInfo[i].vertexesPerUnit + (chunksInfo[i].vertexesPerUnit-2)]);
        }
        else {
          edgeChunk.push(chunk[unit * chunksInfo[i].vertexesPerUnit + vert*2]);
          edgeChunk.push(chunk[unit * chunksInfo[i].vertexesPerUnit + vert*2 + 1]);
        }
      }
    }
    edgeData.push(edgeChunk);
  }
  return {
    surfaceData,
    edgeData,
    chunksInfo,
  }
}

const arc2dRender = (surfaceData, edgeData, chunksInfo, id, root, swizzle) => {
  let arc = root.group({
    id,
  });

  for (const [i, chunk] of surfaceData.entries()) {
    arc
      .array({
        data: chunk,
        channels: 3,
        items: chunksInfo[i].vertexesPerUnit,
        live: false,
      })
      .swizzle({
        order: swizzle,
      })
      .strip({
        classes: ["surface"],
      });
  }

  for (const [i, chunk] of edgeData.entries()) {
    arc
      .array({
        data: chunk,
        channels: 3,
        items: chunksInfo[i].units * 2,
        live: false,
      })
      .swizzle({
        order: swizzle,
      })
      .line({
        classes: ["edge"],
      })
  }
}

export default {
  arc2dGetData,
  arc2dRender,
}