import React from "react";
import { MathQuillErrorMemo } from "../../inputareas/MathQuillError";

import "./ErrorUpdate.css";

export const ErrorUpdate = (props) => {
  let toRender = null;
  if (props.errors.length > 0) {
    toRender = (
      <MathQuillErrorMemo errors={props.errors} />
    );
  }
  else if (props.shouldUpdate) {
    toRender = (
      <button className="updateGraphButton" onClick={props.updateGraph}>▷ Update</button>
    )
  }
  return (
    <div className="ErrorUpdate">
      {toRender} 
    </div>
  );
}