import { findClosingBrace } from "./Cleanup/utils"
import { allowedOrders } from "./allowed";

const assert = (condition, message) => {
  if (!condition) {
    throw new Error(`Parsing Error: ${message}`);
  }
}


const getBoundValue = (latex, i) => {

  // now it is either a "{" (for multi-char bounds), or just a single character (for single-char bounds)
  assert(!(latex[i] === "{" && latex[i+1] === "}"), "Bound cannot be empty.");

  let bound = null;
  if (latex[i] === "{") { // now try to find the other one
    const closingBrace = findClosingBrace(latex, i); // closing brace has assertions built in
    bound = latex.slice(i + 1, closingBrace);
    i += (closingBrace - i) + 1;
  }
  else { // test to make sure it is ACTUALLY only one
    bound = latex.slice(i, i+1); // gets value between
    i++; 
  }
  
  return {
    bound, i,
  }
}

const getDValue = (latex, i) => {
  const acceptableOrders = ["x", "y", "z", "r", "\\theta", "\\rho", "\\phi"]

  assert(i < latex.length, "Unexpected end of integral.");
  assert(latex[i] === "d", "Expected start of integration order.");
  let ord = "";
  i++;
  while (i < latex.length && latex[i] !== "d") {
    ord += latex[i];
    i++;
  }
  assert(ord.length > 0, "Order cannot be empty.");
  assert(acceptableOrders.includes(ord), `Cannot use "${ord}" order of integration.`);

  return {
    ord,
    i,
  }
}

export const parseRawLatex = (latex) => {
  latex = latex.replace(/ /g, ""); // first get rid of all spaces
  const alpha = "abcdef";
  try {
    assert(latex.length > 0, "Integral Field Cannot Be Empty.")
    
    let i = 0;
    const integralCount = (latex.match(/int/g) || []).length;
    assert(integralCount > 0, "Expect at least one integral symbol.");

    const bounds = {};
    const order = [];
    for (let integralNum=0; integralNum<integralCount; integralNum++) {
      // beginning: ensure that integral starts with a "\int"
      assert((latex.slice(i, i + ("\\int".length)) === "\\int"), "Expected start of integral.");


      // now it should be a "_" to denote lower bound
      i += "\\int".length;
      assert(latex[i] === "_", "Expected start of lower bound.");

      // now it is either a "{" (for multi-char bounds), or just a single character (for single-char bounds)
      i += 1
      const { bound: bound1, i: newI1 } = getBoundValue(latex, i);
      bounds[`${alpha[integralNum*2]}`] = bound1;

      // now it should be "^" to denote start of upper bound
      i = newI1;
      assert(latex[i] === "^", "Expected start of upper bound.")

      // now it is either a "{" (for multi-char bounds), or just a single character (for single-char bounds)
      i += 1
      const { bound: bound2, i: newI2 } = getBoundValue(latex, i);  
      bounds[`${alpha[integralNum*2+1]}`] = bound2;
      i = newI2;
    }

    // now should be at the dxdy part
    for (let dNum=0; dNum<integralCount; dNum++) {
      const { ord, i: newI } = getDValue(latex, i);
      order.push(ord);
      i = newI;
    }
    assert(i === latex.length, "Expected end of integral.");


    let orderAllowed = false;
    for (const allowedOrder of allowedOrders) {
      if (order.length !== allowedOrder.length) continue;
      let same = true;
      for (let i=0; i<order.length; i++) {
        if (order[i] !== allowedOrder[i]) {
          same = false;
        }
      }
      orderAllowed = orderAllowed || same;
    }
    assert(orderAllowed, "This order of integration is not allowed");
    return {
      size: integralCount,
      order,
      bounds,
    };
  }
  catch (e) {
    return {
      error: e.message,
    };
  }
};