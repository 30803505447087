import React from 'react';

import "./MathQuillError.css";

const MathQuillError = (props) => {
  if (props.errors.length === 0) {
    return null;
  }
  else 
    return (
      <div>
        {props.errors.map(error => (
          <p key={error.type} className="MathQuillErrorMssg">{error.err}</p>
        ))}
      </div>
    );
};

export const MathQuillErrorMemo = React.memo(MathQuillError, (prev, now) => {
  if (prev.errors.length !== now.errors.length) return false;

  for (let i=0; i<prev.errors.length; i++) {
    if (prev.errors[i] !== now.errors[i]) return false;

  }
  return true;
})