export class Axis3D {
  constructor(root) {
    this.root = root;
    
    this.id = `Axis3D`;

    this.visible = true;
    this.swizzle = 'yzx';

    this.rendered = false;
  }

  render() {
    if (this.rendered) return;
    this.rendered = true;
    let axis = this.root.group({
      id: this.id
    })

    axis
      .axis({
        axis: 1,
        width: 3,
        range: [-2, 2],
        size: 10,
      })
      .axis({
        axis: 2,
        width: 3,
        range: [-2, 2],
        size: 10,
      })
      .axis({
        axis: 3,
        width: 3,
        range: [-2, 2],
        size: 10,
      })
      .array({
        data: [[2, 0, 0], [0, 2, 0], [0, 0, 2]],
        channels: 3,
      })
      .swizzle({
        order: this.swizzle,
      })
      .text({
        data: ['x', 'y', 'z']
      })
      .label({
        color: "black",
        outline: 3,
        offset: [0, 30],
      })
    
    axis
      .grid({
        width: 1,
        divideX: 6,
        divideY: 6,
        axes: [1,3],
        rangeX: [-2, 2],
        rangeY: [-2, 2],
      });

    axis
      .scale({
        divide: 4,
        range: [-2, 2],
        axis: 1,
      })
      .format({
        digits: 3,
      })
      .label({
        color: "rgb(100,100,100)",
        size: 12,
      });
    
    axis
      .scale({
        divide: 4,
        range: [-2, 2],
        axis: 2,
      })
      .format({
        digits: 3,
      })
      .label({
        color: "rgb(100,100,100)",
        size: 12,
        offset: [-30, 0],
      });
  
    axis
      .scale({
        divide: 4,
        range: [-2, 2],
        axis: 3,
      })
      .format({
        digits: 3,
      })
      .label({
        color: "rgb(100,100,100)",
        size: 12,
      });
    

  }

  remove() {
    window.mathbox.select(`#${this.id}`).remove()
  }

  toggleVisibility() {
    this.visible = !this.visible;
    window.mathbox.select(`#${this.id}`).set("visible", this.visible)
  }
}