import React from "react";
import { MessageForm } from "./MessageForm";

export const TitleContainer = () => {
  const [formVisible, setFormVisible] = React.useState(false);
  
  const toggleVisibility = () => {
    setFormVisible(prev => !prev);
  }
  return (
    <div id="TitleContainer">
      <h1>
        Integral3D
      </h1>
      <button onClick={() => toggleVisibility()}>Bug Report/Feedback</button>
      <MessageForm toggleVisibility={toggleVisibility} visible={formVisible} /> 
    </div>
  )
};