import React from "react";
import {Tabs, TabList, Tab, TabPanel} from 'react-tabs';
import { MathQuillStatic } from "../../inputareas/MathQuillStatic";
import { examplesList } from "./Examples";
import { ExampleClickable } from "./ExampleClickable";
import 'react-tabs/style/react-tabs.css';


import "./Information.css";

const Guide = (props) => {
  return (
    <div className="InfoPanel">
      <h1>Guide</h1>
      <h2>Tips</h2>
      <p>
        <strong>To multiply two terms, please explicitly use the asterisk (*) symbol</strong>. This <MathQuillStatic latex={"2\\pi"} /> will
        <strong> not</strong> work. This <MathQuillStatic latex={"2\\cdot \\pi"} /> will work.
      </p>
      <p>
        This tool is primarily designed to show the <i>bounds</i> of multiple integrals. Integrals using
        polar, cylindrical, or sphererical coordinates do not need to include the Jacobian. ({<MathQuillStatic latex={"r"}/>} or 
        <MathQuillStatic latex={"\\rho^2\\sin(\\phi)"}/>). This also means that functions cannot be integrated over. Do not do this: 
        <MathQuillStatic latex={"\\int_0^1 \\int_0^2 (2\\cdot x)\\ dydx"}/>. Do this: <MathQuillStatic latex={"\\int_0^1 \\int_0^2 dydx"}/>.
      </p>
       <p>
          Most permuations of <MathQuillStatic latex={"\\left\\{x,\\ y\\right\\}"}/>,
         <MathQuillStatic latex={"\\left\\{r,\\ \\theta\\right\\}"}/>, <MathQuillStatic latex={"\\left\\{x,\\ y,\\ z\\right\\}"}/>,
         <MathQuillStatic latex={"\\left\\{z,\\ r,\\ \\theta\\right\\}"}/>, and 
         <MathQuillStatic latex={"\\left\\{\\rho,\\ \\phi,\\ \\theta \\right\\}"}/> for integration order are supported.
      </p>
      <h2>Format</h2>
      <p>
        Special characters are typed using plain english (pi, rho, phi, theta).
        Integrals must consist of two to three nested integrals with matching differentials. No constants or functions
        may come between integrals or be integrated over. Use the capital letter "E" for e (2.718...).
      </p>
      <h2>Differentials</h2>
      <p>
        The precision that integrals are rendered can be controlled. The precision value represents the maximum distance between integral subunits
        in that value's respective dimension. For instance, a dy value of 0.2 means that each subunit is at most 0.2 units-y apart. 
        Lower values mean the integral is rendered in greater detail, but it is recommended to first experiment with higher values
        to avoid performance issues.
      </p>
      <h2>Controls</h2>
      <p>
        To change where the camera is looking, right click and drag. On mobile, drag with three fingers to do the same.
        Reset the camera's position using the "Reset Axis" button above.
      </p>
    </div>
  );
}

const About = (props) => {
  return (
    <div className="InfoPanel">
      <h1>About</h1>
      <p>
        This tool is inspired by <a href="https://www.math3d.org/" target="_blank" rel="noopener noreferrer">Math3d</a>, a great site to visualize 3d graphs and objects.
        Graphing is done with the <a href="https://github.com/unconed/mathbox" target="_blank" rel="noopener noreferrer">mathbox</a> library.
      </p>
      <p>Created by Eric H.</p>
    </div>
  );
}


const Examples = (props) => {
  return (
    <div className="InfoPanel">
      <h1>Examples</h1>
      <p>Opens a new integral field. Press update on the field to render the integral.</p>
      <h2>Rectangular 2D</h2>
      <div className="ExamplesCont">
        {examplesList.rec2d.map(example => (
          <ExampleClickable 
            key={example.id}
            onClick={() => props.makeSpecialGraph(example.attrs)}
            label={example.label}
            img={example.imgName}
          />
        ))}
      </div>
      <h2>Polar 2D</h2>
      <div className="ExamplesCont">
        {examplesList.polar2d.map(example => (
          <ExampleClickable 
            key={example.id}
            onClick={() => props.makeSpecialGraph(example.attrs)}
            label={example.label}
            img={example.imgName}
          />
        ))}
      </div>
      <h2>Rectangular 3D</h2>
      <div className="ExamplesCont">
        {examplesList.rec3d.map(example => (
          <ExampleClickable 
            key={example.id}
            onClick={() => props.makeSpecialGraph(example.attrs)}
            label={example.label}
            img={example.imgName}
          />
        ))}
      </div>
      <h2>Cylindrical 3D</h2>
      <div className="ExamplesCont">
        {examplesList.cyl3d.map(example => (
          <ExampleClickable 
            key={example.id}
            onClick={() => props.makeSpecialGraph(example.attrs)}
            label={example.label}
            img={example.imgName}
          />
        ))}
      </div>
      <h2>Spherical 3D</h2> 
      <div className="ExamplesCont">
        {examplesList.circ3d.map(example => (
          <ExampleClickable 
            key={example.id}
            onClick={() => props.makeSpecialGraph(example.attrs)}
            label={example.label}
            img={example.imgName}
          />
        ))}
      </div>
    </div>
  );
}

export const Information = (props) => {
  // console.log(examplesList);
  return (
    <div id="Information" style={{ display: props.visible ? "block" : "none"}}>
      <button className="infoRemoveButton" onClick={props.toggleVisibility} >✖</button>
      <Tabs>
        <TabList>
          <Tab>
            Guide
          </Tab>
          <Tab>
            Examples
          </Tab>
          <Tab>
            About
          </Tab>
        </TabList>
        <TabPanel>
          <Guide />
        </TabPanel>
        <TabPanel>
          <Examples makeSpecialGraph={props.makeSpecialGraph} />
        </TabPanel>
        <TabPanel>
          <About />
        </TabPanel>
      </Tabs>
    </div>
  )
}