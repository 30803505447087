import { cylData } from "../GraphObjects/objectData";

const cyl3dGetData = (a, b, c, d, e, f, maxDelOut, maxDelMid, maxDelIn, order) => {
  let surfaceData = {
    arcs: [],
    out: [],
    caps: [],
  };
  let edgeData = [];
  let chunksInfo = {
    arcs: [],
    out: [],
    caps: [],
  };
  const maxChunk = Math.pow(2, 14);

  let chunks = {
    arcs: {
      vertexesSoFar: 0,
      vertexesPerUnit: 0,
      curr: [],
    },
    out: {
      vertexesSoFar: 0,
      vertexesPerUnit: 0,
      curr: [],
    },
    caps: {
      vertexesSoFar: 0,
      vertexesPerUnit: 0,
      curr: [],
    }
  }

  const segsOut = Math.ceil(Math.abs(b - a) / maxDelOut);
  const delOut = (b - a) / segsOut;

  for (let i=0; i<segsOut; i++) {
    const iterOut = i * delOut + a;

    const segsMid = Math.ceil(Math.abs(d(iterOut) - c(iterOut)) / maxDelMid);
    const delMid = (d(iterOut) - c(iterOut)) / segsMid;

    for (let j=0; j<segsMid; j++) {
      const iterMid = j * delMid + c(iterOut);

      const segsIn = Math.ceil(Math.abs(f(iterOut, iterMid) - e(iterOut, iterMid)) / maxDelIn);
      const delIn = (f(iterOut, iterMid) - e(iterOut, iterMid)) / segsIn;

      for (let k=0; k<segsIn; k++) {
      const iterIn = k * delIn + e(iterOut, iterMid);
          
        if (i === 0 || i === segsOut - 1 ||
          j === 0 || j === segsMid - 1 ||
          k === 0 || k === segsIn - 1) 
        {
          Object.keys(chunks).forEach(comp => {
            if (chunks[comp].vertexesSoFar + chunks[comp].vertexesPerUnit > maxChunk) {
              chunksInfo[comp].push({
                units: chunks[comp].vertexesSoFar / chunks[comp].vertexesPerUnit,
                vertexesPerUnit: chunks[comp].vertexesPerUnit,
              })
              surfaceData[comp].push(chunks[comp].curr);
              chunks[comp].curr = [];
              chunks[comp].vertexesSoFar = 0;
            }
          });
          // asssume dz dr dtheta
          const { arcs, outside, caps} 
            = cylData(iterMid, iterMid + delMid, iterOut, iterOut + delOut, iterIn, iterIn + delIn, 1);
          const objs = [arcs, outside, caps];
          for (const [i, comp] of Object.keys(chunks).entries()) {
            chunks[comp].vertexesPerUnit = objs[i].length;
            chunks[comp].curr.push(...objs[i]);
            chunks[comp].vertexesSoFar += chunks[comp].vertexesPerUnit;
          }
        }
      }
    }
  }
  Object.keys(chunks).forEach(comp => {
    chunksInfo[comp].push({
      units: chunks[comp].vertexesSoFar / chunks[comp].vertexesPerUnit,
      vertexesPerUnit: chunks[comp].vertexesPerUnit,
    })
    surfaceData[comp].push(chunks[comp].curr);
    chunks[comp].curr = [];
    chunks[comp].vertexesSoFar = 0;
  });

  for (const [i, chunk] of surfaceData.arcs.entries()) {
    let edgeChunk = {a: [], b: [], c: []};
    for (let vert=0; vert<chunksInfo.arcs[i].vertexesPerUnit/4; vert++) {
      for (let unit=0; unit<chunksInfo.arcs[i].units; unit++) {
        edgeChunk.a.push(chunk[unit*chunksInfo.arcs[i].vertexesPerUnit + vert*2]);
        edgeChunk.a.push(chunk[unit*chunksInfo.arcs[i].vertexesPerUnit + vert*2+1]);

        edgeChunk.a.push(chunk[unit*chunksInfo.arcs[i].vertexesPerUnit + vert*2 + chunksInfo.arcs[i].vertexesPerUnit/2]);
        edgeChunk.a.push(chunk[unit*chunksInfo.arcs[i].vertexesPerUnit + vert*2+1 + chunksInfo.arcs[i].vertexesPerUnit/2]);
      }
    }

    for (let vert=0; vert<2; vert++) {
      for (let unit=0; unit<chunksInfo.arcs[i].units * chunksInfo.arcs[i].vertexesPerUnit / 2; unit++) {
        edgeChunk.b.push(chunk[unit*2 + vert]);
      }
    }

    for (let vert=0; vert<2; vert++) {
      const vertStart = (vert%2 ? 0 : chunksInfo.arcs[i].vertexesPerUnit/2);
      for (let unit=0; unit<chunksInfo.arcs[i].units; unit++) {
        for (let vertNum=vertStart; vertNum<vertStart+chunksInfo.arcs[i].vertexesPerUnit/2; vertNum++) {
          edgeChunk.c.push(chunk[unit*chunksInfo.arcs[i].vertexesPerUnit + vertNum]);
        }
      }
    }
    edgeData.push(edgeChunk);
  }
  return {
    surfaceData,
    edgeData,
    chunksInfo,
  }
}

const cyl3dRender = (surfaceData, edgeData, chunksInfo, id, root, swizzle) => {
  let cyl = root.group({
    id, 
  });

  Object.keys(chunksInfo).forEach(comp => {
    for (const [i, chunk] of surfaceData[comp].entries()) {
      switch (comp) {
        case "out":
        case "arcs": {  
          cyl
            .array({
              data: chunk,
              channels: 3,
              items: chunksInfo[comp][i].vertexesPerUnit/2,
              live: false,
            })
            .swizzle({
              order: swizzle,
            })
            .strip({
              classes: ["surface"],
            });
          break;
        }
        case "caps": {
          cyl
            .array({
              data: chunk,
              channels: 3,
              items: 4,
              live: false,
            })
            .swizzle({
              order: swizzle,
            })
            .strip({
              classes: ["side"],
            });
          break;
        }
      }
    }
  });

  for (const [i, chunk] of edgeData.entries()) {
    cyl
      .array({
        data: chunk.a,
        channels: 3,
        items: chunksInfo.arcs[i].units * 4,
        live: false,
      })
      .swizzle({
        order: swizzle,
      })
      .line({
        classes: ["edge"],
      });
    
    cyl
      .array({
        data: chunk.b,
        channels: 3,
        items: chunksInfo.arcs[i].units * chunksInfo.arcs[i].vertexesPerUnit / 2,
        live: false,
      })
      .swizzle({
        order: swizzle,
      })
      .line({
        classes: ["edge"],
      })
    
    cyl 
      .array({
        data: chunk.c,
        channels: 3,
        items: chunksInfo.arcs[i].units * chunksInfo.arcs[i].vertexesPerUnit / 2,
        live: false,
      })
      .swizzle({
        order: swizzle,
      })
      .line({
        classes: ["edge"],
      })
  }
};

export default {
  cyl3dGetData,
  cyl3dRender,
}