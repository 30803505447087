import { findClosingBrace } from "./utils"

export const cleanLatex = (lat) => {
  if (lat.length === 0) {
    throw new Error("Field cannot be empty.")
  }
  lat = replaceMath(lat)
  lat = fracToDivision(lat)
  lat = replaceInverseTrig(lat, "csc")
  lat = replaceInverseTrig(lat, "sec")
  lat = replaceInverseTrig(lat, "cot")
  return lat
}


const fracToDivision = (expr) => {
  /* 
    frac( )( )
    |   | || |
    a   b cd e
  */
  const frac = 'frac'
  const a = expr.indexOf(frac)
  const b = a + frac.length

  if (a < 0) { return expr }

  const c = findClosingBrace(expr, b)
  
  const d = c + 1;
  const e = findClosingBrace(expr, d)
  // Remove frac, and add "/"
  const newExpr = 
    expr.slice(0, a) + 
    "(" + expr.slice(b, c + 1) + '/' +expr.slice(d, e + 1) + ")" +
    expr.slice(e + 1)

  return fracToDivision(newExpr)
}

const replaceInverseTrig = (expr, trig) => {
  /*
    xxx( )
    |  | |  
    a  b c
  */
  while (expr.length >= 4 && expr.indexOf(trig) !== -1) {
    const a = expr.indexOf(trig);
    const b = a + trig.length;
    const bef = expr.slice(0, a);
    const c = findClosingBrace(expr, b);
    const aft = expr.slice(c+1);
    const mid = expr.slice(a+3, c+1);
    switch (trig) {
      case "csc": {
        expr = `${bef}(1/sin${mid})${aft}` 
        break;
      }  
      case "sec": {
        expr = `${bef}(1/cos${mid})${aft}`
        break;
      }
      case "cot": {
        expr = `${bef}(cos${mid}/sin${mid})${aft}`
        break;
      }
    }
  }
  return expr;
}

const replaceMath = (lat) => {
  let mp = {
    " ": "",
    "{": "(",
    "}": ")",
    "left": "",
    "right": "",
    "\\cdot": "*",
    "\\pi": Math.PI,
    "\\phi": "φ",
    "\\theta": "θ",
    "\\rho": "ρ",

    "arcsin": "asin",
    "arccos": "acos",
    "arctan": "atan",
    "log": "log10",
    
    "\\": "",
  }
  for (const pr of Object.keys(mp)) {
    lat = lat.replaceAll(pr, mp[pr])
  }
  return lat
}