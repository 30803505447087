export const findClosingBrace = (str, startIdx) => {
  const braces = {
    '[': ']',
    '<': '>',
    '(': ')',
    '{': '}'
  }

  const openingBrace = str[startIdx]

  const closingBrace = braces[openingBrace]

  if (closingBrace === undefined) {
    throw Error(`${str} does not contain an opening brace at position ${startIdx}.`)
  }

  let stack = 1

  // eslint-disable-next-line no-plusplus
  for (let j = startIdx + 1; j < str.length; j++) {
    if (str[j] === openingBrace) {
      stack += +1
    }
    else if (str[j] === closingBrace) {
      stack += -1
    }
    if (stack === 0) {
      return j
    }
  }

  // stack !== 0
  throw Error(`${str} has a brace that opens at position ${startIdx} but does not close.`)
}