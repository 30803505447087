import React from "react";

import "./MessageForm.css";

export const MessageForm = (props) => {
  const [type, setType] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [latex, setLatex] = React.useState("");
  const [email, setEmail] = React.useState("");

  const submitForm = (e) => {
    e.preventDefault();
    if (type.length + message.length + latex.length + email.length > 1000) alert("Message too long.");
    else if (type === "") alert("Message subject is a required field.");
    else if (message === "") alert("Message is a required field.");
    else {
      const data = {
        messageType: type,
        message,
        latex,
        email,
      }
      fetch("/message", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      .then(res => {
        if (!res.ok) {
          throw new Error("Not ok");
        }
        props.toggleVisibility();
        setTimeout(() => alert("Your message was submitted."), 200);
      })
      .catch(error => {
        setTimeout(() => alert("Error. Your message could not be submitted. Please try again later."), 200);
      })
    }
  }

  const radioButtonChange = (label) => (e) => {
    if (e.target.value === "on")
      setType(label);
  }

  return (
    <div id="MessageForm" style={{ display: props.visible ? "block" : "none" }}>
      <button className="formRemoveButton" onClick={props.toggleVisibility} >✖</button>
      <p>Please submit feedback or any bugs encountered.</p>
      <form onSubmit={submitForm}>
        <div className="newInput">
          <p>Message subject (required)</p>
          <div>
            <label>
              <input type="radio" name="subject" checked={type === "bug"} onChange={radioButtonChange("bug")}></input>
              Bug
            </label>
          </div>
          <div>
            <label>
              <input type="radio" name="subject" checked={type === "suggestion"} onChange={radioButtonChange("suggestion")}></input>
              Suggestion
            </label>
          </div>
          <div>
            <label>
              <input type="radio" name="subject" checked={type === "other"} onChange={radioButtonChange("other")}></input>
              Other
            </label>
          </div>
        </div>
        <div className="newInput">
          <label>
            <p>Message (required)</p>
            <input type="text" name="message" value={message} onChange={(e) => setMessage(e.target.value)}></input>
          </label>
        </div>
        <div className="newInput">
          <label>
            <p>Equation that caused the issue (directly copy paste) (optional)</p>
            <input type="text" name="latex" value={latex} onChange={(e) => setLatex(e.target.value)} ></input>
          </label>
        </div>
        <div className="newInput">
          <label>
            <p>Email (optional)</p>
            <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)}></input>
          </label>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
