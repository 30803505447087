import React from 'react';
import { genericGraph } from '../../../../utils/graphdata/GenericGraph';
import { GenericInterface } from '../../../graphinterfaces/GenericInterface';
import { Information } from '../../../information/info/Information';

import "./GraphContainer.css"

const GraphsControlInfo = (props) => {
  const [infoVisible, setInfoVisible] = React.useState(false);

  const toggleInfoVisibility = () => {
    setInfoVisible(prev => !prev);
  }
  return (
    <div id="GraphsControlInfo">
      <button className="GraphControlButton" id="newGraph" onClick={props.makeGraph}>New Graph</button>
      <button className="GraphControlButton" id="helpButton" onClick={toggleInfoVisibility}>Information</button>
      <button className="GraphControlButton" onClick={props.resetAxis}>Reset Axis</button>
      <Information visible={infoVisible} makeSpecialGraph={props.makeSpecialGraph} toggleVisibility={toggleInfoVisibility} />
    </div>
  );
}

export const GraphContainer = () => {
  const [graphIds, setGraphIds] = React.useState([]);
  const [specialIds, setSpecialIds] = React.useState({});

  const makeNewGraph = () => {
    const graph = new genericGraph(window.mathbox.select("root"));
    window.mathboxGraphs[graph.id] = graph;
    setGraphIds([...graphIds, graph.id])
    return graph.id;
  };
  const removeGraph = (removeId) => () => {
    window.mathboxGraphs[removeId].remove();
    delete window.mathboxGraphs[removeId];
    setGraphIds(graphIds.filter(id => id !== removeId));
  };
  const resetAxis = () => {
    window.mathboxControls.target.setX(0);
    window.mathboxControls.target.setY(0);
    window.mathboxControls.target.setZ(0);
  }

  const makeSpecialGraph = (attrs) => {
    // console.log(attrs);
    const newId = makeNewGraph();
    // console.log(newId);
    setSpecialIds(old => ({
      ...old,
      [newId]: attrs,
    }));
  }

  React.useEffect(() => {
    const defaultGraph = {
      equationLatex: "\\int _0^{3\\cdot \\pi }\\int _{0.6}^1\\int _{0.17\\cdot \\theta }^{0.2\\cdot \\theta }d\\rho d\\phi d\\theta ",
      initialUpdate: true,
      order: ["\\rho", "\\phi", "\\theta"],
      dels: {
        "\\rho": 0.5,
        "\\phi": 0.1,
        "\\theta": 0.1,
      },
      color: "#B5EAD7",
    }
    makeSpecialGraph(defaultGraph);
  }, []);

  return (
    <div id="GraphAndControlsContainer">
      <GraphsControlInfo makeGraph={makeNewGraph} resetAxis={resetAxis} makeSpecialGraph={makeSpecialGraph} />
      <div id="GraphContainer">
        {graphIds.map(id => {
          if (id in specialIds) {
            return <GenericInterface key={id} graphId={id} removeGraph={removeGraph} initSettings={specialIds[id]} />
          }
          else {
            return <GenericInterface key={id} graphId={id} removeGraph={removeGraph}/>
          }
        })}
      </div>
    </div>
  );
};