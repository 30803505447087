import React from 'react'
import { LeftColumnContainer } from './components/containers/LeftColumnContainer';

const App = () => {
  return (
    <LeftColumnContainer />
  );
}

export default App;
