import React from "react";

export const MathQuillStatic = (props) => {
  const wrapper = React.useRef();
  let staticField = React.useRef();

  React.useEffect(() => {
    wrapper.current.innerHTML = props.latex;
    staticField = window.MQ.StaticMath(wrapper.current);
  }, [props.latex]);

  return (
    <span ref={wrapper}></span>
  )
}