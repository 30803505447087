import { rectData } from "../GraphObjects/objectData";

const rec2dGetData = (a, b, c, d, otherVar, maxDelOut, maxDelIn, order) => {
  let surfaceData = [];
  let edgeData = [];
  let chunksInfo = [];
  const maxChunk = Math.pow(2, 14);
  
  let chunks = {
    vertexesPerUnit: 0,
    vertexesSoFar: 0,
    curr: [],
  };
  
  const segsOut = Math.ceil(Math.abs(b - a) / maxDelOut);
  const delOut = (b - a) / segsOut;

  for (let i=0; i<segsOut; i++) {
    const iterOut = i * delOut + a;

    const segsIn = Math.ceil(Math.abs(d(iterOut) - c(iterOut)) / maxDelIn);
    const delIn = (d(iterOut) - c(iterOut)) / segsIn;

    for (let j=0; j<segsIn; j++) {
      const iterIn = j * delIn + c(iterOut);

      if (chunks.vertexesSoFar + chunks.vertexesPerUnit >= maxChunk) {
        chunksInfo.push({
          units: chunks.vertexesSoFar / chunks.vertexesPerUnit,
          vertexesPerUnit: chunks.vertexesPerUnit,
        });
        surfaceData.push(chunks.curr);
        chunks.curr = [];
        chunks.vertexesSoFar = 0;
      } 
      let recData = null;
      if (order.join(",") === "x,y")
        recData = rectData(iterIn, iterIn + delIn, iterOut, iterOut + delOut, otherVar).recData;
      else if (order.join(",") === "y,x")
        recData = rectData(iterOut, iterOut + delOut, iterIn, iterIn + delIn, otherVar).recData;
      if (recData === null) throw new Error("this data shouldn't be null...");

      chunks.vertexesPerUnit = recData.length;
      chunks.curr.push(...recData);
      chunks.vertexesSoFar += chunks.vertexesPerUnit;
    }
  }
  surfaceData.push(chunks.curr);
  chunksInfo.push({
    units: chunks.vertexesSoFar / chunks.vertexesPerUnit,
    vertexesPerUnit: chunks.vertexesPerUnit,
  })

  // now doing the edges
  for (const [i, chunk] of surfaceData.entries()) {
    let edgeChunk = [];
    for (let vert=0; vert<4; vert++) {
      for (let unit=0; unit<chunksInfo[i].units; unit++) {
        let toAdd = vert;
        if (toAdd === 2) toAdd = 3;
        else if (toAdd === 3) toAdd = 2;
        edgeChunk.push(chunk[unit*chunksInfo[i].vertexesPerUnit + toAdd]);
      }
    }
    edgeData.push(edgeChunk);
  }
  return {
    surfaceData,
    edgeData,
    chunksInfo,
  }
}

const rec2dRender = (surfaceData, edgeData, chunksInfo, id, root, swizzle) => {
  let rect = root.group({
    id,
  });

  for (const [i, chunk] of surfaceData.entries()) {
    rect
      .array({
        data: chunk,
        channels: 3,
        items: chunksInfo[i].vertexesPerUnit,
        live: false,
      })
      .swizzle({
        order: swizzle,
      })
      .strip({
        classes: ["surface"],
      });
  }

  for (const [i, chunk] of edgeData.entries()) {
    rect
      .array({
        data: chunk,
        channels: 3,
        items: chunksInfo[i].units,
        live: false,
      })
      .swizzle({
        order: swizzle,
      })
      .line({
        classes: ["edge"],
      })
  }
}

export default {
  rec2dGetData,
  rec2dRender,
}