export const examplesList = {
rec2d: [
  {
    id: 1,
    attrs: {
      equationLatex: "\\int _0^{2\\cdot \\pi }\\int _0^{\\sin \\left(x\\right)}dydx",
      initialUpdate: true,
      order: ["y", "x"],
      dels: {
        "y": 0.5,
        "x": 0.05,
      },
      color: "#FFFFFF",
    },
    label: "Sine Wave",
    imgName: "rec2dsin.jpg",
  },
  {
    id: 2,
    attrs: {
      equationLatex: "\\int _{-1}^1\\int _0^{\\min \\left(0.5,abs\\left(x\\right)\\right)}dydx",
      initialUpdate: true,
      order: ["y", "x"],
      dels: {
        "y": 0.2,
        "x": 0.02,
      },
      color: "#E2F0CB",
    },
    label: "Min Operator",
    imgName: "rec2dmin.jpg",
  }
],

polar2d: [
  {
    id: 1,
    attrs: {
      equationLatex: "\\int _0^{2\\cdot \\pi }\\int _{0.5}^1drd\\theta ",
      initialUpdate: true,
      order: ["r", "\\theta"],
      dels: {
        "r": 0.1,
        "\\theta": 0.05,
      },
      color: "#FFB7B2",
    },
    label: "Doughnut",
    imgName: "polar2dnut.jpg",
  },
  {
    id: 2,
    attrs: {
      equationLatex: "\\int _0^{\\arctan \\left(\\frac{3}{2}\\right)}\\int _0^{3\\cdot \\sec \\left(\\theta \\right)}drd\\theta",
      initialUpdate: true,
      order: ["r", "\\theta"],
      dels: {
        "r": 0.5,
        "\\theta": 0.015,
      },
      color: "#FFDAC1",
    },
    label: "Triangle",
    imgName: "polar2dtri.jpg",
  }
],

rec3d: [
  {
    id: 1,
    attrs: {
      equationLatex: "\\int _{-1}^1\\int _{-1}^1\\int _0^{x^2+y^2}dzdydx",
      initialUpdate: true,
      order: ["z", "y", "x"],
      dels: {
        "z": 0.5,
        "y": 0.1,
        "x": 0.1,
      },
      color: "#ADD8E6",
    },
    label: "Valley",
    imgName: "rec3dvalley.jpg",
  },
  {
    id: 2,
    attrs: {
      equationLatex: "\\int _{0.3}^2\\int _{-3}^3\\int _0^{\\frac{1}{x\\cdot \\sqrt{2\\cdot \\pi }}\\cdot E^{-\\frac{1}{2}\\cdot \\left(\\frac{y}{x}\\right)^2}}dzdydx",
      initialUpdate: true,
      order: ["z", "y", "x"],
      dels: {
        "z": 0.5,
        "y": 0.07,
        "x": 0.07,
      },
      color: "#E2F0CB",
    },
    label: "Gaussian",
    imgName: "rec3dgaussian.jpg",
  },
  {
    id: 3,
    attrs: {
      equationLatex: "\\int _{-1}^1\\int _{-\\sqrt{1-x^2}}^{\\sqrt{1-x^2}}\\int _0^{\\sqrt{1-x^2-y^2}}dzdydx",
      initialUpdate: true,
      order: ["z", "y", "x"],
      dels: {
        "z": 1,
        "y": 0.05,
        "x": 0.05,
      },
      color: "#C7CEEA",
    },
    label: "Hemisphere",
    imgName: "rec3dhemi.jpg",
  }
],

cyl3d: [
  {
    id: 1,
    attrs: {
      equationLatex: "\\int _0^1\\int _{0.2}^1\\int _{0.2}^{0.6}dzdrd\\theta ",
      initialUpdate: true,
      order: ["z", "r", "\\theta"],
      dels: {
        "z": 0.1,
        "r": 0.1,
        "\\theta": 0.1,
      },
      color: "#B5EAD7",
    },
    label: "Slice",
    imgName: "cyl3dslice.jpg",
  },  
  {
    id: 2,
    attrs: {
      equationLatex: "\\int _0^{\\pi }\\int _0^{\\cos \\left(3\\cdot \\theta \\right)}\\int _0^{1-r\\cdot \\cos \\left(\\theta \\right)+0.1\\cdot r\\cdot \\sin \\left(\\theta \\right)}dzdrd\\theta ",
      initialUpdate: true,
      order: ["z", "r", "\\theta"],
      dels: {
        "z": 1,
        "r": 0.1,
        "\\theta": 0.05,
      },
      color: "#FFB7B2",
    },
    label: "Flower",
    imgName: "cyl3dflower.jpg",
  },
  {
    id: 3,
    attrs: {
      equationLatex: "\\int _0^{2\\cdot \\pi }\\int _0^2\\int _{\\theta -0.5}^{\\theta }dzdrd\\theta  ",
      initialUpdate: true,
      order: ["z", "r", "\\theta"],
      dels: {
        "z": 0.5,
        "r": 0.1,
        "\\theta": 0.1,
      },
      color: "#FFFFFF",
    },
    label: "Spiral Staircase",
    imgName: "cyl3dstair.jpg",
  },
],

circ3d: [
  {
    id: 1,
    attrs: {
      equationLatex: "\\int _1^2\\int _{0.4}^1\\int _{0.7}^1d\\rho d\\phi d\\theta ",
      initialUpdate: true,
      order: ["\\rho", "\\phi", "\\theta"],
      dels: {
        "\\rho": 0.5,
        "\\phi": 0.04,
        "\\theta": 0.05,
      },
      color: "#FF9AA2",
    },
    label: "Slice",
    imgName: "circ3dslice.jpg",
  },
  {
    id: 2,
    attrs: {
      equationLatex: "\\int _0^{2\\cdot \\pi }\\int _{\\frac{\\pi }{12}}^{\\frac{\\pi }{4}}\\int _{\\frac{1}{\\sqrt{2}}\\cdot \\sec \\left(\\phi \\right)}^1d\\rho d\\phi d\\theta ",
      initialUpdate: true,
      order: ["\\rho", "\\phi", "\\theta"],
      dels: {
        "\\rho": 0.05,
        "\\phi": 0.07,
        "\\theta": 0.07,
      },
      color: "#C7CEEA",
    },
    label: "UFO",
    imgName: "circ3dufo.jpg",
  },
  {
    id: 3,
    attrs: {
      equationLatex: "\\int _0^{2\\cdot \\pi }\\int _0^{\\theta }\\int _{\\theta -0.3}^{\\theta }d\\rho d\\phi d\\theta ",
      initialUpdate: true,
      order: ["\\rho", "\\phi", "\\theta"],
      dels: {
        "\\rho": 0.5,
        "\\phi": 0.1,
        "\\theta": 0.1,
      },
      color: "#ADD8E6",
    },
    label: "Shell",
    imgName: "circ3dshell.jpg",
  },
],
};