export const rectData = (x1, x2, y1, y2, z) => {
  const recData = [
    [x1, y1, z],
    [x1, y2, z],
    [x2, y1, z],
    [x2, y2, z],
  ]
  return { recData }
}

export const rect3dData = (x1, x2, y1, y2, z1, z2) => {
  const rec3dData = [
    ...rectData(x1, x2, y1, y2, z1).recData,
    ...rectData(x1, x2, y1, y2, z2).recData,
  ]
  return { rec3dData }
}

export const arccData = (rad1, rad2, theta1, theta2, z, samples) => {
  const toCart = (rad, ang) => [rad*Math.cos(ang), rad*Math.sin(ang), z]
  let arcData = [];
  const intv = (theta2 - theta1) / (samples);
  for (let i=0; i<=samples; i++) {
    arcData.push(toCart(rad1, theta1 + i*intv))
    arcData.push(toCart(rad2, theta1 + i*intv))
  }
  return { arcData }
}

export const cylData = (rad1, rad2, theta1, theta2, z1, z2, samples) => {
  const { arcData: arc0Data } = arccData(rad1, rad2, theta1, theta2, z1, samples)
  const { arcData: arc1Data } = arccData(rad1, rad2, theta1, theta2, z2, samples)
  const arcs = [...arc0Data, ...arc1Data]

  const outside = []
  for (let i=0; i<=samples; i++) {
    outside.push(arc0Data[i*2], arc1Data[i*2])
  }
  for (let i=0; i<=samples; i++) {
    outside.push(arc0Data[i*2+1], arc1Data[i*2+1])
  }

  const caps = [
    arc0Data[0], arc0Data[1], arc1Data[0], arc1Data[1],
    arc0Data[arc0Data.length-2], arc0Data[arc0Data.length-1], arc1Data[arc1Data.length-2], arc1Data[arc1Data.length-1],
  ]
  return {
    arcs,
    outside, 
    caps
  }
}

export const circData = (rho1, rho2, theta1, theta2, phi1, phi2, wSamples=2, hSamples=2) => {

  const bigSphere = []
  const smallSphere = []
  const spheres = []
  
  const sides = {
    a: [],
    b: [],
    c: [],
    d: [],
  }

  for (let i=0; i<hSamples; i++) { // phi 
    // do not require < + 1 as with wSamples becaise we are alreay taking phiNext! (i + 1 when i==hsamples-1)
    let phiCurr = phi1 + ((phi2 - phi1) / hSamples) * i
    let phiNext = phi1 + ((phi2 - phi1) / hSamples) * (i + 1)

    if (phiCurr === 0) phiCurr += 0.001
    else if (Math.abs(Math.PI-phiNext) < 0.01) phiNext -= 0.001
    
    const thetaDiv = (theta2 - theta1) / wSamples
    for (let j=0; j<wSamples+1; j++) { // require < + 1 because nneed to gap to other side
      const theta = theta1 + thetaDiv * j

      const innerPts = [
        [rho1 * Math.sin(phiCurr) * Math.cos(theta), // x
        rho1 * Math.sin(phiCurr) * Math.sin(theta), // y
        rho1 * Math.cos(phiCurr)], // z
        
        [rho1 * Math.sin(phiNext) * Math.cos(theta), // x
        rho1 * Math.sin(phiNext) * Math.sin(theta), // y
        rho1 * Math.cos(phiNext)], // z
      ]
      smallSphere.push(...innerPts)

      const outerPts = [
        [rho2 * Math.sin(phiCurr) * Math.cos(theta), // x
        rho2 * Math.sin(phiCurr) * Math.sin(theta), // y
        rho2 * Math.cos(phiCurr)], // z
        
        [rho2 * Math.sin(phiNext) * Math.cos(theta), // x
        rho2 * Math.sin(phiNext) * Math.sin(theta), // y
        rho2 * Math.cos(phiNext)], // z
      ]
      bigSphere.push(...outerPts)

      // happens when start of theta
      if (j===0) {
        sides.b.push(outerPts[0], innerPts[0])

        // so you can get the one at the end
        /*
              1    3    5    7    [9]
              2    4    6    8    [10]

           j: 0   0/1  0/1  0/1    1

          In this diagram we always get [1-8] via index 0, but need 10:
          can be gotten with index 1 in the last iteration
        */
        if (i===hSamples-1) {
          sides.b.push(outerPts[1], innerPts[1])
        }
      }
      if (j===wSamples) {
        sides.a.push(outerPts[0], innerPts[0])
        if (i===hSamples-1) {
          sides.a.push(outerPts[1], innerPts[1])
        }
      }

      // occurs when vertical is ending
      if (i===0) {
        sides.c.push(outerPts[0], innerPts[0])
      }
      // see above: same principle but since i stays same value for whole inner loop,
      // don't need another condition
      if (i===hSamples-1) {
        sides.d.push(outerPts[1], innerPts[1])
      }
    }
  }
  spheres.push(...smallSphere, ...bigSphere)

  return {
    spheres,
    sides,
  }
}