import { parser } from "./initParser"
import { concurCleanLatex } from "./Cleanup/concurrentCleaners";
import { cleanLatex } from "./Cleanup/postCleaners";

export function cleanConcurrent(lat) {
  lat = concurCleanLatex(lat)
  return lat
}

const checkValidSymbols = (expr, acceptableVars) => {
  const mathSymbols = [
    "abs", "acos", "asin", "atan", "ceil", "cos", "cosh", "exp", "floor", "ln", "log10", "round",
    "sin", "sinh", "sqrt", "tan", "tanh", "min", "max"
  ]  
  const symbsIn = expr.symbols();
  for (const symb of symbsIn) {
    if (acceptableVars.includes(symb) === false && mathSymbols.includes(symb) === false) {
      throw new Error(`'${symb}' variable not allowed.`)
    }
  }
}

export function parseToComputable(lat, acceptableBounds) {
  try {
    lat = cleanLatex(lat);
    let expr = parser.parse(lat);
    checkValidSymbols(expr, acceptableBounds);
    let res = null;
    if (acceptableBounds.length === 0) {
      res = expr.evaluate();
    }
    else {
      let availVars = "";
      for (let i=0; i<acceptableBounds.length; i++) {
        availVars += acceptableBounds[i];
        if (i < acceptableBounds.length - 1)
          availVars += ",";
      }
      res = expr.toJSFunction(availVars)
    }
    return res;
  }
  catch (e) {
    if (e.message === "unexpected TPAREN: )") {
      return "Function cannot be empty"
    }
    return e.message
  }
}
