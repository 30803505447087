import React from 'react';
import { MathQuillInputMemo } from '../../inputareas/MathQuillInput';
import { MathQuillStatic } from '../../inputareas/MathQuillStatic';
import { MathQuillErrorMemo } from '../../inputareas/MathQuillError';
import { ErrorUpdate } from './ErrorUpdate';
import { parseRawLatex } from '../../../utils/parsing/parseRawLatex';
import { parseToComputable } from '../../../utils/parsing/index';

import "./EquationControls.css"

export const EquationControls = (props) => {
  const [order, setOrder] = React.useState(props.initSettings?.order || []);
  const [orderDels, setOrderDels] = React.useState(props.initSettings?.dels || {});
  const [errors, setErrors] = React.useState([]);
  const [bounds, setBounds] = React.useState({});
  const [shouldUpdate, setShouldUpdate] = React.useState(false);

  const initLatex = React.useRef(props.initSettings?.equationLatex || ""); // the "true" latex is actually stored in the mathquill field, so this ref is onyl needed once

  React.useEffect(() => {
    const newOrderDels = {}
    for (const ord of order) {
      if (ord in orderDels) {
        newOrderDels[ord] = orderDels[ord];
      }
      else {
        newOrderDels[ord] = 0.5;
      }
    } 
    setOrderDels(newOrderDels);
  }, [order]);

  const setComputableBounds = (latexBounds, boundOrder) => {
    // now check if bounds work
    let errs = [];
    const newBounds = {}
    let acceptableVars = []
    const alpha = "abcdef";

    for (let boundSet=0; boundSet<boundOrder.length; boundSet++) {
      for (let boundLevel=0; boundLevel<2; boundLevel++) {
        const parsedBound = parseToComputable(latexBounds[alpha[boundSet*2 + boundLevel]], acceptableVars);
        newBounds[alpha[boundSet*2 + boundLevel]] = parsedBound;
        if (typeof parsedBound === "string") // is an error
          errs.push({type: `bound${alpha[boundSet*2 + boundLevel]}`, err: `bound${alpha[boundSet*2+boundLevel]}: ${parsedBound}`});
      }
      const nextAllowed = boundOrder[boundOrder.length - 1 - boundSet];
      if (nextAllowed === "\\theta") acceptableVars.push("θ");
      else if (nextAllowed === "\\rho") acceptableVars.push("ρ");
      else if (nextAllowed === "\\phi") acceptableVars.push("φ");
      else acceptableVars.push(nextAllowed);
    }
    if (errs.length === 0) {
      setBounds(newBounds);
    }
    return errs;
  }

  const setNewLatex = (newLatex) => { // note that this function is delivered ONLY ONCE, so run risk of stale closure
    const parsed = parseRawLatex(newLatex);
    const errs = [];
    if (parsed.order !== undefined) {
      errs.push(...setComputableBounds(parsed.bounds, parsed.order));
      if (errs.length === 0) {
        setShouldUpdate(true); // updates only when all bounds are validated
        setOrder(parsed.order);
      }
    }
    else {
      errs.push({type: "main", err: parsed.error});
    }
    setErrors(errs);
  };

  const updateGraph = () => {
    window.mathboxGraphs[props.graphId].remove();
    window.mathboxGraphs[props.graphId].computeData(order, bounds, orderDels);
    window.mathboxGraphs[props.graphId].render();
    setShouldUpdate(false);
  }

  const updateDel = (ord) => (val) => {
    setShouldUpdate(true);
    setOrderDels(old => ({...old, [ord]: val}));
  }
  return (
    <div className="EquationControls">
      <div className="Equation">
        <MathQuillInputMemo 
          latex={initLatex.current} 
          onEdit={setNewLatex} 
          initFocus={true} 
          inputClass="mathquillInt" 
          onMountEditEvent={props.initSettings?.initialUpdate}
        />
      { // Since we first set the order, this will run BEFORE the useEffect that changes the del changes, so
        // we will access undefined elements in the object. Therefore, we have to check if all objects have a 
        // correspsoning value in dels, or in other words, after the useffect runs
      order.reduce((prev, key) => (prev && (key in orderDels)), true) && order.length > 0
      ? (<div className="controlDels">
          {order.map(ord => (
          <div key={ord}> 
            <MathQuillStatic latex={`d${ord}: `} />
            <MathQuillInputMemo 
              latex={`${orderDels[ord]}`} 
              onEdit={updateDel(ord)} 
              initFocus={false} 
              inputClass="mathquillDel"
            />
          </div>
          ))}
        </div>) 
      : null} 
      </div>
      <div>
        <ErrorUpdate errors={errors} shouldUpdate={shouldUpdate} updateGraph={updateGraph} />
      </div>
    </div>
  )
}