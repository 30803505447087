import { rect3dData } from "../GraphObjects/objectData";

const rec3dGetData = (a, b, c, d, e, f, maxDelOut, maxDelMid, maxDelIn, order) => {
  let surfaceData = [];
  let edgeData = [];
  let chunksInfo = [];
  const maxChunk = Math.pow(2, 14);

  let chunks = {
    vertexesPerUnit: 0,
    vertexeSoFar: 0,
    curr: [],
  };

  const segsOut = Math.ceil(Math.abs(b - a) / maxDelOut);
  const delOut = (b - a) / segsOut;

  for (let i=0; i<segsOut; i++) {
    const iterOut = i * delOut + a;

    const segsMid = Math.ceil(Math.abs(d(iterOut) - c(iterOut)) / maxDelMid);
    const delMid = (d(iterOut) - c(iterOut)) / segsMid;

    for (let j=0; j<segsMid; j++) {
      const iterMid = j * delMid + c(iterOut);

      const segsIn = Math.ceil(Math.abs(f(iterOut, iterMid) - e(iterOut, iterMid)) / maxDelIn);
      const delIn = (f(iterOut, iterMid) - e(iterOut, iterMid)) / segsIn;

      for (let k=0; k<segsIn; k++) {
        const iterIn = k * delIn + e(iterOut, iterMid);

        if (i === 0 || i === segsOut - 1 ||
            j === 0 || j === segsMid - 1 ||
            k === 0 || k === segsIn - 1) 
        {
          if (chunks.vertexeSoFar + chunks.vertexesPerUnit >= maxChunk) {
            chunksInfo.push({
              units: chunks.vertexeSoFar / chunks.vertexesPerUnit,
              vertexesPerUnit: chunks.vertexesPerUnit,
            });
            surfaceData.push(chunks.curr);
            chunks.curr = [];
            chunks.vertexeSoFar = 0;
          }
          const { rec3dData: r3d } = rect3dData(iterOut, iterOut + delOut, iterMid, iterMid + delMid, iterIn, iterIn + delIn);
          const newCube = [
            r3d[0], r3d[1], r3d[3], r3d[2], 
            r3d[4], r3d[5], r3d[7], r3d[6],
            r3d[0], r3d[2], r3d[6], r3d[4],
            r3d[3], r3d[7], r3d[6], r3d[2],
            r3d[0], r3d[4], r3d[5], r3d[1],
            r3d[1], r3d[3], r3d[7], r3d[5],
          ];
          chunks.vertexesPerUnit = newCube.length;
          chunks.curr.push(...newCube);
          chunks.vertexeSoFar += chunks.vertexesPerUnit;
        }
      }
    }
  }
  surfaceData.push(chunks.curr);
  chunksInfo.push({
    units: chunks.vertexeSoFar / chunks.vertexesPerUnit,
    vertexesPerUnit: chunks.vertexesPerUnit,
  });

  const edgeConnections = [
    [0, 1],
    [1, 2],
    [2, 3],
    [0, 3],

    [4, 5],
    [5, 6],
    [6, 7],
    [4, 7],

    [0, 4],
    [1, 5],
    [2, 6], 
    [3, 7],
  ];
  for (const [i, chunk] of surfaceData.entries()) {
    let edgeChunk = [];
    for (let vertNum=0; vertNum<2; vertNum++) {
      for (const connection of edgeConnections) {
        for (let unit=0; unit<chunksInfo[i].units; unit++) {
          edgeChunk.push(chunk[unit*chunksInfo[i].vertexesPerUnit + connection[vertNum]]);
        }
      }
    }
    edgeData.push(edgeChunk);
  }
  return {
    surfaceData,
    edgeData,
    chunksInfo,
  }
};

const rec3dRender = (surfaceData, edgeData, chunksInfo, id, root, swizzle) => {
  let rect = root.group({
    id,
  });

  for (const [i, chunk] of surfaceData.entries()) {
    rect
      .array({
        data: chunk,
        channels: 3,
        items: 4,
        live: false,
      })
      .swizzle({
        order: swizzle,
      })
      .face({
        classes: ["surface"],
      })
  }

  for (const [i, chunk] of edgeData.entries()) {
    rect
      .array({
        data: chunk,
        channels: 3,
        items: chunksInfo[i].units * 12, // because therre are 12 edges on a cube
        live: false,
      })
      .swizzle({
        order: swizzle,
      })
      .line({
        classes: ["edge"],
      });
  }
};

export default {
  rec3dGetData,
  rec3dRender,
}