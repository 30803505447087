import React from 'react';
import { Axis3D } from '../../utils/graphdata/axis3D';

import "./AxisInterface.css";

export const AxisInterface = (props) => {
  const [id, setId] = React.useState(null)
  React.useEffect(() => {
    const axis = new Axis3D(window.mathbox.select("root"));
    window.mathboxGraphs[axis.id] = axis
    setId(axis.id)
    axis.render()
  }, [])
  const toggleAxis = () => {
    // console.log(window.mathboxGraphs);
    window.mathboxGraphs[id].toggleVisibility()
  }
  const setToOrigin = () => {
    window.mathboxControls.target.setX(0)
    window.mathboxControls.target.setY(0)
    window.mathboxControls.target.setZ(0)
  }
  return (
    null
    // <div className="AxisInterface">
    //   Axis 
    //   <button onClick={toggleAxis}>Toggle</button>
    //   <button onClick={setToOrigin}>Set to origin</button>
    // </div>
  )
}