import React from "react";
import { concurCleanLatex } from '../../utils/parsing/Cleanup/concurrentCleaners'

import "./MathQuillInput.css";

 
const MathQuillInput = (props) => {
  const wrapper = React.useRef()

  React.useEffect(() => { // runs only once configurring the mathfield
    wrapper.current.innerHTML = props.latex; // note that props.latex is only need ONCE
    let mathField = window.MQ.MathField(wrapper.current)
    mathField.config({
      autoCommands: "pi theta rho phi int",
      handlers: {
        edit(field) {
          { // clean output while typing, as mathquill doesnt convert sqrt to sqrt symbol
            let lat = field.latex();
            const potenClean = concurCleanLatex(lat)
            if (lat !== potenClean)
              field.latex(potenClean)
          }

          props.onEdit(field.latex())
        }
      }
    });
    if (props.initFocus)
      mathField.focus();
    
    if (props.onMountEditEvent) {
      mathField.typedText(' ');
      mathField.keystroke("Backspace")
    }
  }, []);

  return (
    <span ref={wrapper} className={`${props.inputClass} mathQuillReg`}></span>
  );
}

export const MathQuillInputMemo = React.memo(MathQuillInput, (prev, now) => {
  return true; // as in this one doesn't need reupdating, as mathquill internally sets itself
})