import { circData } from "../GraphObjects/objectData";

const circ3dGetData = (a, b, c, d, e, f, maxDelOut, maxDelMid, maxDelIn, order) => {
  let surfaceData = {
    spheres: [],
    sides: {
      a: [],
      b: [],
      c: [],
      d: [],
    }
  };
  let edgeData = {
    a: [],
    b: [],
    c: [],
    d: [],
  };
  let chunksInfo = {
    spheres: [],
    sides: {
      a: [],
      b: [],
      c: [],
      d: [],
    }
  };
  let maxChunk = Math.pow(2, 14);

  let chunks = {
    spheres: {
      vertexesSoFar: 0,
      vertexesPerUnit: 0,
      curr: [],
    },
    sides: {
      a: {
        vertexesSoFar: 0,
        vertexesPerUnit: 0,
        curr: [],
      },
      b: {
        vertexesSoFar: 0,
        vertexesPerUnit: 0,
        curr: [],
      },
      c: {
        vertexesSoFar: 0,
        vertexesPerUnit: 0,
        curr: [],
      },
      d: {
        vertexesSoFar: 0,
        vertexesPerUnit: 0,
        curr: [],
      },
    }
  };

  const segsOut = Math.ceil(Math.abs(b - a) / maxDelOut);
  const delOut = (b - a) / segsOut;

  for (let i=0; i<segsOut; i++) {
    const iterOut = i * delOut + a;

    const segsMid = Math.ceil(Math.abs(d(iterOut) - c(iterOut)) / maxDelMid);
    const delMid = (d(iterOut) - c(iterOut)) / segsMid;

    for (let j=0; j<segsMid; j++) {
      const iterMid = j * delMid + c(iterOut);

      const segsIn = Math.ceil(Math.abs(f(iterOut, iterMid) - e(iterOut, iterMid)) / maxDelIn);
      const delIn = (f(iterOut, iterMid) - e(iterOut, iterMid)) / segsIn;

      for (let k=0; k<segsIn; k++) {
      const iterIn = k * delIn + e(iterOut, iterMid);
          
        if (i === 0 || i === segsOut - 1 ||
          j === 0 || j === segsMid - 1 ||
          k === 0 || k === segsIn - 1) 
        {
          if (chunks.spheres.vertexesSoFar + chunks.spheres.vertexesPerUnit >= maxChunk) {
            chunksInfo.spheres.push({
              units: chunks.spheres.vertexesSoFar / chunks.spheres.vertexesPerUnit,
              vertexesPerUnit: chunks.spheres.vertexesPerUnit,
            })
            surfaceData.spheres.push(chunks.spheres.curr);
            chunks.spheres.curr = [];
            chunks.spheres.vertexesSoFar = 0;
          }

          Object.keys(chunks.sides).forEach(side => {
            if (chunks.sides[side].vertexesSoFar + chunks.sides[side].vertexesPerUnit > maxChunk) {
              chunksInfo.sides[side].push({
                units: chunks.sides[side].vertexesSoFar / chunks.sides[side].vertexesPerUnit,
                vertexesPerUnit: chunks.sides[side].vertexesPerUnit,
              })
              surfaceData.sides[side].push(chunks.sides[side].curr);
              chunks.sides[side].curr = [];
              chunks.sides[side].vertexesSoFar = 0;
            }
          });

          const { spheres, sides } = 
            circData(iterIn, iterIn + delIn, iterOut, iterOut + delOut, iterMid, iterMid + delMid, 1, 1);
          
          chunks.spheres.vertexesPerUnit = spheres.length;
          chunks.spheres.curr.push(...spheres);
          chunks.spheres.vertexesSoFar += chunks.spheres.vertexesPerUnit;

          Object.keys(chunks.sides).forEach(side => {
            chunks.sides[side].vertexesPerUnit = sides[side].length;
            chunks.sides[side].curr.push(...sides[side]);
            chunks.sides[side].vertexesSoFar += chunks.sides[side].vertexesPerUnit;
          });
        }
      }
    }
  }
  chunksInfo.spheres.push({
    units: chunks.spheres.vertexesSoFar / chunks.spheres.vertexesPerUnit,
    vertexesPerUnit: chunks.spheres.vertexesPerUnit,
  })
  surfaceData.spheres.push(chunks.spheres.curr);


  Object.keys(chunks.sides).forEach(side => {
    chunksInfo.sides[side].push({
      units: chunks.sides[side].vertexesSoFar / chunks.sides[side].vertexesPerUnit,
      vertexesPerUnit: chunks.sides[side].vertexesPerUnit,
    })
    surfaceData.sides[side].push(chunks.sides[side].curr);
  });

  for (const side of Object.keys(chunksInfo.sides)) {
    for (const [i, chunk] of surfaceData.sides[side].entries()) {
      let edgeChunk = {a: [], b: []};

      for (let vert=0; vert<chunksInfo.sides[side][i].vertexesPerUnit / 2; vert++) {
        for (let unit=0; unit<chunksInfo.sides[side][i].units; unit++) {
          edgeChunk.a.push(chunk[unit * chunksInfo.sides[side][i].vertexesPerUnit + vert * 2]);
          edgeChunk.a.push(chunk[unit * chunksInfo.sides[side][i].vertexesPerUnit + vert * 2 + 1]);        
        }
      }

      if (side === "a" || side === "b") {
        edgeData[side].push(edgeChunk);
        continue;
      };

      for (let vert=0; vert<2; vert++) {
        for (let unit=0; unit<chunksInfo.sides[side][i].units; unit++) {
          const vert1 = 0;
          const vert2 = chunksInfo.sides[side][i].vertexesPerUnit - 2;
          edgeChunk.b.push(chunk[unit*chunksInfo.sides[side][i].vertexesPerUnit + vert1 + vert]);
          edgeChunk.b.push(chunk[unit*chunksInfo.sides[side][i].vertexesPerUnit + vert2 + vert]);
        }
      }

      edgeData[side].push(edgeChunk)
    }
  }
  return {
    surfaceData,
    edgeData,
    chunksInfo,
  }
};

const circ3dRender = (surfaceData, edgeData, chunksInfo, id, root, swizzle) => {
  let circ = root.group({
    id,
  });
  for (const [i, chunk] of surfaceData.spheres.entries()) {
    circ
      .array({
        data: chunk,
        channels: 3,
        items: chunksInfo.spheres[i].vertexesPerUnit/2/1,
        live: false,
      })
      .swizzle({
        order: swizzle,
      })
      .strip({
        classes: ["surface"],
      })
  }

  for (const side of Object.keys(chunksInfo.sides)) {
    for (const [i, chunk] of surfaceData.sides[side].entries()) {
      circ
        .array({
          data: chunk,
          channels: 3,
          items: chunksInfo.sides[side][i].vertexesPerUnit,
          live: false,
        })
        .swizzle({
          order: swizzle,
        })
        .strip({
          classes: ['side'],
        });
    }
  }

  for (const side of Object.keys(chunksInfo.sides)) {
    for (const [i, chunk] of edgeData[side].entries()) {
      circ  
        .array({
          data: chunk.a,
          channels: 3,
          items: chunksInfo.sides[side][i].units * 2,
          live: false,
        })
        .swizzle({
          order: swizzle,
        })
        .line({
          classes: ["edge"],
        })
      
      if (side === "a" || side === "b") continue;

      circ
        .array({
          data: chunk.b,
          channels: 3,
          items: chunksInfo.sides[side][i].units * 2,
          live: false,
        })
        .swizzle({
          order: swizzle,
        })
        .line({
          classes: ["edge"],
        })
    }
  }
};

export default {
  circ3dGetData,
  circ3dRender,
}