import React from 'react';
import "./GenericInterface.css";
import "./ThemeColors.css";

import { EquationControls } from './equation/EquationControls';
import { SettingsControls } from "./settings/SettingsControls"

import { FiSettings } from 'react-icons/fi';
import { IoChevronBackCircleOutline } from 'react-icons/io5';

export const GenericInterface = (props) => {
  const [display, setDisplay] = React.useState(true); // graphing or settings
  const [color, setColor] = React.useState("#FFFFFF");

  const themeColor = `color${color.slice(1)}`;

  return (
    <div className={`${themeColor} GenericInterface`}>
      <button className="removeButton" onClick={props.removeGraph(props.graphId)} >✖</button>
      <div className="controlsAndSettingsToggleButton">
        <div className="integralControls" style={{ display: display ? "block" : "none"}}>
          <EquationControls graphId={props.graphId} initSettings={props.initSettings} />
        </div>
        <div className="settingsControls" style={{ display: !display ? "block" : "none"}}>
          <SettingsControls graphId={props.graphId} initSettings={props.initSettings} changeColor={(col) => setColor(col)} />
        </div>
        <div className="settingsButtonCont" onClick={() => setDisplay(curr => !curr)} >
          <span className="settingsButton">
            {
              display 
              ? <FiSettings />
              : <IoChevronBackCircleOutline />
            }
          </span>
        </div>
      </div>
    </div>
  )
};